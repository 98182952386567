import {atom} from 'recoil';

/*
   Manual - https://recoiljs.org/docs/basic-tutorial/atoms/
*/
export const isUserAuthed = atom({
  key: 'isUserAuthed',
  default: false,
});

export const categorySelection = atom({
  key: 'categorySelection',
  default: 'Restaurants',
});

export const profileData = atom({
  key: 'profileData',
  default: [],
});