import React, { Component, useEffect } from "react";
import { Nav, NavDropdown } from 'react-bootstrap';
import { categorySelection, isUserAuthed } from '../store/index.js';
import {useRecoilState} from 'recoil';
import { useCookies } from "react-cookie";
import eventBus from "./EventBus";

export const NavBar = () => {
  const [catSelection, setCatSelection] = useRecoilState(categorySelection);
  const [authStatus, setAuthStatus] = useRecoilState(isUserAuthed);
  const [cookies, setCookie] = useCookies(["user"]);

  /* Used to react to state change in catSelection atom value */
  useEffect(() => {
      console.log("Dispatching event[refreshNavBar] for successful login");
      eventBus.dispatch("refreshNavBar", { message: "Time to refresh!" });
  }, [cookies.fbName]);

  return <NavBarComponent setCatSelection={setCatSelection}
                          catSelection={catSelection}
                          cookies={cookies}
                          authStatus={authStatus}

                          />;
};

class NavBarComponent extends Component {

	  constructor(props) {
          super(props);
          console.log("NavBar.constructor");

          if(this.props.cookies.fbAccessToken) {
            this.state = {
                fbName: this.props.cookies.fbName.split(/\s+/)[0],
                fbImgUrl: this.props.cookies.fbImgUrl,
                refreshNavBar: false
            };
          } else {
            this.state = {
                fbName: '',
                fbImgUrl: '',
                refreshNavBar: false
            };
          }
	  }

      handleEventBusEvent() {
          /* TODO figure out how to refresh NAVBAR on cookie change / fbName being set */
          console.log("received refreshNavBar event : ", this.props.cookies.fbName);
          if(this.props.cookies.fbName == null) { return; }
              this.setState({
                    fbName: this.props.cookies.fbName.split(/\s+/)[0],
                    fbImgUrl: this.props.cookies.fbImgUrl,
              });
      }

	  async componentDidMount() {
          console.log("NavBar.componentDidMount");

          eventBus.on("refreshNavBar", (data) => this.handleEventBusEvent());
	  }

      componentWillUnmount() {
        eventBus.remove("refreshNavBar");
      }

	  handleClick = () => {
          console.log("NavBar.handleClick()");
      };

      handleCategorySelection = (selection) => {
          this.props.setCatSelection(selection);

          console.log("NavBar.Updated category selection to : ", selection);
      }

	  render() {
	    console.log("NavBar.render");

        return (
          <Nav fill justify variant="pills" >
            <NavDropdown title={this.props.catSelection} id="nav-dropdown">
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Restaurants")}>Restaurants</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Bars")}>Bars</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Desserts")}>Desserts</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Shows")}>Shows</NavDropdown.Item>
              {/*
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Coffee")}>Coffee</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Recipes")}>Recipes</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Outdoor")}>Outdoor Activity</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.handleCategorySelection("Indoor")}>Indoor Activity</NavDropdown.Item>
              */}
            </NavDropdown>
            <Nav.Item>
              <Nav.Link eventKey="1" href="#/About">
                About
              </Nav.Link>
            </Nav.Item>
            {this.state.fbName ?
            <Nav.Item>
            <div class='parent inline-flex-parent' display='inline-flex'>
              <div class='child'>
                  <Nav.Link eventKey="2" title="xxx">
                    Welcome {this.state.fbName}!
                  </Nav.Link>
              </div>
              <div class='child'>
                    <img
                      src={this.state.fbImgUrl}
                      className='img-thumbnail'
                      alt='...'
                      width="35"
                      height="35"
                    />
              </div>
            </div>

            </Nav.Item>
            : null
            }
          </Nav>
        );
      }
}