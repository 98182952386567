import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import "./App.css";
import { Router } from 'react-router-dom';
import { history } from './store/history.js';
import {LoginWrapper} from "./components/FacebookButton";
import {PrivateRoute} from './components/PrivateRoute.js';
import {GimmeOptions} from './components/GimmeOptions.js';
import {PresentOptions} from './components/PresentOptions.js';
import {IngestOptions} from './components/IngestOptions.js';
import {NavBar} from "./components/NavBar.js";
import { Switch, Route, Redirect } from 'react-router-dom';
import { Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure(awsconfig);

export default function App() {
    return (
      <Router history={history}>
          <NavBar />
          <Card className="text-center">
            <Switch>
                <PrivateRoute exact path="/" component={GimmeOptions} />
                <PrivateRoute exact path="/PresentOptions" component={PresentOptions} />
                <Route path="/login" component={LoginWrapper} />
                {/* TODO Lock down this route to admin access only */}
                <Route path="/ingest" component={IngestOptions} />
                <Redirect from="*" to="/" />
            </Switch>
            {/*<Image src="https://boringbug.files.wordpress.com/2017/11/options-and-choices.jpg" roundedCircle />*/}
          </Card>
      </Router>
    );
}