/*
    - Used for communication between components, for re-redering independent components etc.
    - HowTo
      - Component A dispatch.eventX
      - Component B subscribes to on(eventX) to take action(e.g. state change to trigger re-render)
*/
const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
