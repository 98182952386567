import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import { RecoilRoot, useRecoilSnapshot } from "recoil";
import { CookiesProvider } from "react-cookie";

Amplify.configure(config);

/* Debug code to log current snapshot of Recoil state mgmt
   - Alternate approach - go to localhost website Inspect page -> in console type window.$recoilDebugStates
   - In result, expand -> 0 -> atomValues -> _hamt -> _root -> value -> contents
*/
const SnapshotRecoilState = () => {
  const snapshot = useRecoilSnapshot();
  console.log("snapshot");
  console.log(snapshot);
  return <a href='test'>Click Me!</a>;
}

ReactDOM.render(
  <RecoilRoot>
    <CookiesProvider>
      <React.StrictMode>
            <App />
        {/* Only comment to enable Recoil snapshot dump debug mode <SnapshotRecoilState />*/}
      </React.StrictMode>
    </CookiesProvider>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
