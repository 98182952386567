import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Card, Button, Dropdown } from 'react-bootstrap';
import { Analytics } from 'aws-amplify';

export const GimmeOptions = () => {
  return <GimmeOptionsButton />;
};

class GimmeOptionsButton extends Component {

	  constructor(props) {
          super(props);
          console.log("GimmeOptions.constructor");
          this.state = {
             redirect: false
          };
	  }

	  async componentDidMount() {
          console.log("GimmeOptionsButton.renderedSuccess");
	  }

	  handleClick = () => {
          console.log("GimmeOptionsButton.handleClick()");
          this.setState({ redirect: true });
      };

	  render() {
          if (this.state.redirect) {
              console.log("onGimmeOptionsRequest, redirecting to PresentOptions");
              Analytics.record({ name: 'GimmeOptions.onClick' });

              return <Redirect to='/PresentOptions' />
          }

          return (
                <div>
                     <Card className="text-center">
                        <Card.Header>
                            <Card.Body>
                              <Button variant="info" onClick={this.handleClick}>GimmeOptions</Button>{' '}
                            </Card.Body>
                       </Card.Header>
                     </Card>
                </div>
              );
        }
}