import React, { Component } from "react";
import { API } from 'aws-amplify';
import { listOptions, getOption } from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { Card, Button } from 'react-bootstrap';

const categories = ['RESTAURANTS', 'BARS', 'DESSERTS', 'SHOWS', 'OUTDOOR_ACTIVITIES', 'INDOOR_ACTIVITIES', 'RECIPES'];

export const IngestOptions = () => {
    return <IngestOptionsComponent />;
};

function csvToArray(str, delimiter = ",") {

  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter)
    const el = headers.reduce(function (object, header, index) {
      /* It is reading the last attr, imageUrl with a \r character which causes issues, so doing a trim() to remove that */
      object[header.trim()] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}

class IngestOptionsComponent extends Component {

	  constructor(props) {
          super(props);
          console.log("IngestOptionsComponent.constructor");
          this.state = {
              isIngestionComplete: false
          };
	  }

	  handleClick = async() => {
          console.log("IngestOptionsButton.handleClick()");

          const csvFile = document.getElementById("csvFile");
          const input = csvFile.files[0];
          const reader = new FileReader();

          reader.onload = function (e) {
            const text = e.target.result;
            const data = csvToArray(text);

            // DEBUG data
            // document.write(JSON.stringify(data));

            data.map(item => {
                const checker = value =>
                  [item.category].some(element => value.includes(element));

                if(categories.filter(checker).length === 0) {
                    console.log(item.category, ' is not a valid or supported category. Skipping processing row with id : ', item.id);
                    return;
                }

                const getEntry = {
                    id: item.id
                };

                const getRecord = API.graphql({ query: getOption, variables: {id: item.id}});

                /* Resolve the promise and read the result */
                getRecord.then(function(result) {

                  let isCreateOp = false;
                  if(result.data.getOption === null) {
                      isCreateOp = true;
                      console.log("Ingesting with CreateOption for key : ", item.id);
                  } else {
                      console.log("Ingesting with UpdateOption for key : ", item.id);
                  }

                  /* Write or update entry based on result */
                  if(isCreateOp) {
                      const createEntry = {
                          // currently ready id from the input file,
                          //  but if uuid needed switch to id: uuidv4() and import { v4 as uuidv4 } from 'uuid';
                          id: item.id,
                          name: item.name,
                          description: item.description,
                          url: item.url,
                          category: item.category,
                          imageUrl: item.imageUrl.trim()
                      };

                      const createRecord = API.graphql({ query: mutations.createOption, variables: {input: createEntry}});
                  } else {
                      const updateEntry = {
                          id: item.id,
                          name: item.name,
                          description: item.description,
                          url: item.url,
                          category: item.category,
                          imageUrl: item.imageUrl.trim()
                      };

                      const updateRecord = API.graphql({ query: mutations.updateOption, variables: {input: updateEntry}});
                  }

                });

            })
          };

          reader.readAsText(input);

          this.setState({ isIngestionComplete: true });
      };

	  render() {
            if (this.state.isIngestionComplete) {
              console.log("Ingestion completed...");
              return (
                  <Card.Header>
                      <Card.Body>
                          Ingestion Complete!
                      </Card.Body>
                 </Card.Header>
              );
            }

            return (
              <div>
                <form id="myForm">
                  <input type="file" id="csvFile" accept=".csv" />
                </form>

                <Card.Header>
                    <Card.Body>
                        <Button variant="info" onClick={this.handleClick}>INGEST!</Button>{' '}
                    </Card.Body>
               </Card.Header>
               </div>
            );
          }
}