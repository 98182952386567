import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import FacebookLogin from 'react-facebook-login';
import authconfig from '../config';
import {isUserAuthed} from '../store/index.js';
import {useRecoilState} from 'recoil';
import { Redirect } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { Analytics } from 'aws-amplify';

/* References
   - Uses the FB JS SDK loaded @ runtime
   - API Spec sheet here - https://developers.facebook.com/docs/javascript
*/

export const LoginWrapper = () => {
  // `useSetRecoilState` subscribes the component to atom updates
  console.log("FacebookButton.bind.setAuthStatusToLoginWrapper");
  const [authStatus, setAuthStatus] = useRecoilState(isUserAuthed);
  const [cookies, setCookie] = useCookies(["user"]);

  return <FacebookButton setAuthStatus={setAuthStatus} setCookie={setCookie} cookies={cookies} />;
};

export default class FacebookButton extends Component {

	  constructor(props) {
          super(props);
          // If cookies has valid fbAccessToken then reuse that instead of doing another round of login+Auth
          // TODO move to PrivateRoute instead
          if(this.props.cookies.fbAccessToken) {
              console.log("Valid fbAccessToken exists already ", this.props.cookies.fbAccessToken);
              this.props.setAuthStatus(true);
              this.state = {
                  redirect: true
              };
          } else {
              this.state = {
                 redirect: false
              };
          }
	  }

	  async componentDidMount() {
          console.log("FacebookButton.renderedSuccess");
	  }

	  statusChangeCallback = response => {
          console.log("FacebookButton.statusChangecallback");
          if (response.status === "connected") {
                this.handleResponse(response.authResponse);
              } else {
                this.handleError(response);
              }
        };

	  checkLoginState = () => {
            console.log("FacebookButton.checkLoginState");
            window.FB.getLoginStatus(this.statusChangeCallback);
	  };

	  handleClick = () => {
          console.log("FacebookButton.handleClick()");
          window.FB.login(this.checkLoginState, {scope: "public_profile,email"});
      };

	  handleError(error) {
          alert(error);
      }

      saveProfileData(response, expiresIn) {
            this.props.setCookie("fbEmailAddress", response.email, { path: "/", maxAge: expiresIn });
            this.props.setCookie("fbName", response.name, { path: "/", maxAge: expiresIn });
            this.props.setCookie("fbImgUrl", response.picture.data.url, { path: "/", maxAge: expiresIn });
            console.log("Cookie value set for email address, name, imgUrl");
      }

	  async handleResponse(data) {
              console.log("FbAuthRequest.handlingResponse. Processed FBAuth response =>");
              // DEBUG log to see response from FB.Auth request
              // console.log(data);

		      const { accessToken: token, expiresIn } = data;
		      const expires_at = expiresIn * 1000 + new Date().getTime();

              // Cookie-fy access token
              this.props.setCookie("fbAccessToken", data.accessToken, { path: "/", maxAge: expiresIn });
              this.props.setCookie("fbUserId", data.userID, { path: "/", maxAge: expiresIn });
              this.props.setCookie("fbExpiresAt", expires_at, { path: "/", maxAge: expiresIn });
              console.log("Cookie set for fbAccessToken, fbUserId, fbExpiresAt", data.accessToken, data.userID, expires_at);

              console.log("Fetching logged in profile info to display");
              let user = null;
              let that = this;
              window.FB.api('/me', { fields: 'id, name, email, picture' }, function(response)
              {
                  console.log("Successfully fetched profile info to display");
                  user = response.email;
                  // DEBUG log to view response info
                  // console.log(response);

                  // Save profile data to cookies
                  that.saveProfileData(response, expiresIn);
              });

              Analytics.record({ name: 'FacebookButton.FBAuth.Success' });

		      try {

                    const response = await Auth.federatedSignIn(
                            "facebook",
                            { token, expires_at },
                            user
                          );

                    console.log("Completed AWS Auth.federatedSignIn...");

                    // auth succeeded, so update the atom
                    this.props.setAuthStatus(true);
                    // Set redirect to true on successful Auth
                    this.setState({ redirect: true });

                    Analytics.record({ name: 'FacebookButton.AWSAuth.Success' });
                  } catch (e) {
                        console.log("Got an exception" + e);
                        throw e;
                  }
		    }

	  render() {
          if (this.state.redirect) {
              console.log("onSuccessfulFBAuth, redirecting to GimmeOptions");
              return <Redirect to='/GimmeOptions' />
          }

          return (
                /* Manual - https://www.npmjs.com/package/react-facebook-login */
                <FacebookLogin
                  appId={authconfig.social.PROD_FB}
                  //cookie={true}
                  //xfbml={true}
                  //autoLoad={true} - only enable if I eventually want auto-auth on initial render of page
                  fields="name,email,picture"
                  scope="public_profile,user_friends"
                  callback={() => {
                    // TODO no longer needed, being invoked after auth success in handleResponse?
                    //this.props.setAuthStatus(true);
                  }}
                  disableMobileRedirect={true}
                  onClick={this.handleClick}
                  icon="fa-facebook" />

              );
        }
}
