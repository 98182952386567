/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:282c757c-8584-4da4-953a-383a508ca1fc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_piGH2IWWd",
    "aws_user_pools_web_client_id": "5qvgd7o0v3sgs0ucrshdrcg1pf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://neh364pcufgzxbduzadvtod7ve.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lxu2vlpn6ba4dmd42qzcht3dh4",
    "aws_mobile_analytics_app_id": "de83a14d2f104ad7bf74fc6d5d48acd0",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
