import React, { Component, useEffect } from "react";
import { API } from 'aws-amplify';
import { listOptions } from '../graphql/queries';
import { Card, Button, Spinner } from 'react-bootstrap';
import { categorySelection } from '../store/index.js';
import {useRecoilState} from 'recoil';
import eventBus from "./EventBus";
import { Analytics } from 'aws-amplify';

export const PresentOptions = () => {
  const [catSelection, setCatSelection] = useRecoilState(categorySelection);

  /* Used to react to state change in catSelection atom value */
  useEffect(() => {
    console.log("Dispatching event[categoryChanged] for new category : ", catSelection);
    eventBus.dispatch("categoryChanged", { message: "New Category Selection" });
  }, [catSelection]);

  return <PresentOptionsComponent catSelection={catSelection} />;
};

class PresentOptionsComponent extends Component {

	  constructor(props) {
          super(props);
          console.log("PresentOptionsComponent.constructor");

          this.state = {
              apiResponse: {},
              apiResponseReady: false,
              refreshCards: false,
              emptyResults: false,
              cardOneObject: {},
              cardTwoObject: {},
              cardThreeObject: {}
          };
	  }

      async dbListOptions() {
          let filter = {
              category: {
                  eq: this.props.catSelection.toUpperCase()
              }
          };

          console.log('Making listOptions call to DDB with category filter: ', this.props.catSelection.toUpperCase());
          // TODO need to handle pagination token - https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/#paginating-queries
          // TODO move to a DatabaseAccessLayer class
          const apiData = await API.graphql({ query: listOptions, variables: { filter: filter }});
          Analytics.record({ name: 'PresentOptions.Database.listOptions.Success' });

          /* DEBUG Log full API response */
          // console.log("Logging full API response results... ", apiData.data.listOptions.items);

          let itemList = apiData.data.listOptions.items.slice();

          /* Shuffle the list before saving so that we get different results each time */
          itemList = itemList.sort( () => Math.random() - 0.5);

          if(itemList.length > 0) {
              /* Pop the last three elements and assign to current cards */
              this.setState({ cardOneObject: itemList.pop() });
              this.setState({ cardTwoObject: itemList.pop() });
              this.setState({ cardThreeObject: itemList.pop() });

              /* Save remaining list to the state to use as needed */
              this.setState({ apiResponse: itemList });
              this.setState({ apiResponseReady: true });
          } else {
              Analytics.record({ name: 'PresentOptions.Database.listOptions.NoResults' });
              console.log('No results from Dynamo for category filter: ', this.props.catSelection);
              this.setState({ apiResponseReady: true });
              this.setState({ emptyResults: true });
          }
      }

      handleEventBusEvent() {
            console.log('PresentOptions.categoryChanged Event received');
            /* Reset all state */
            this.setState({
              apiResponse: {},
              apiResponseReady: false,
              refreshCards: false,
              emptyResults: false,
              cardOneObject: {},
              cardTwoObject: {},
              cardThreeObject: {}
            });
            /* Initiate new DB call based on latest  category selection */
            this.dbListOptions();
      }

	  async componentDidMount() {
          console.log("PresentOptionsComponent.renderedSuccess");

          this.dbListOptions();

          eventBus.on("categoryChanged", (data) => this.handleEventBusEvent());
	  }

      componentWillUnmount() {
        eventBus.remove("categoryChanged");
      }

	  handleClick = () => {
          console.log("PresentOptionsComponent.handleClick()");
      };

      handleCardClick = (cardNumber, isAccepted) => {
          console.log("Card selection clicked for cardNumber : ", cardNumber, " with selection : ", isAccepted);

          if(isAccepted) {
              Analytics.record({ name: 'PresentOptions.Cards.AcceptedOption' });
              /* TODO Handle YES accepted - re-route to the configured URL? */
              return;
          }

          /* If user rejected the card, re-populate card with next element */
          if(!isAccepted) {
              if(!(this.state.apiResponse.length > 0)) {
                  /* TODO : Initiate next page request to Dynamo since no more results left in local cache */
                  console.log("ERROR! There are no more elements left in the local list. Keeping cards as is");
                  return;
              }

              Analytics.record({ name: 'PresentOptions.Cards.RejectedOption' });

              if(cardNumber === 1) {
                  this.setState({ cardOneObject: this.state.apiResponse.pop() });
                  this.setState({ refreshCards: true });
              } else if(cardNumber === 2) {
                  this.setState({ cardTwoObject: this.state.apiResponse.pop() });
                  this.setState({ refreshCards: true });
              } else if(cardNumber === 3) {
                  this.setState({ cardThreeObject: this.state.apiResponse.pop() });
                  this.setState({ refreshCards: true });
              } else {
                  console.log("Unexpected cardNumber arg : ", cardNumber, ". Ignoring...");
              }
              // Confirm the pop() reduced the length of array
              /* console.log(this.state.apiResponse.length);
              console.log("Post-update state of cardOne: ", this.state.cardOneObject,
                  ", cardTwo: ", this.state.cardTwoObject,
                  ", cardThree: ", this.state.cardThreeObject);
              */
          }
      }

	  render() {
          if (!this.state.apiResponseReady && this.state.emptyResults) {
              console.log("onPresentOptionsRender, no listNextOptions response yet...LOADING");

              return (
                  <Card.Header>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                  </Card.Header>
              );
          } else if(this.state.apiResponseReady && this.state.emptyResults) {
              console.log("onPresentOptionsRender, empty results from call");

              return (
                  <Card.Header>
                      <Card.Body>
                        No results!
                      </Card.Body>
                  </Card.Header>
              );
          } else {
              console.log("onPresentOptionsRender, rendering results in cards...");
              // TODO - restrict the src image to static dimensions
              return (
                    <div>
                         <Card className="text-center">
                                <Card.Header>Option #1</Card.Header>
                                <Card.Body>
                                  <Card.Title>
                                      {this.state.cardOneObject.name} <br />
                                  </Card.Title>
                                  <Card.Text>
                                      {this.state.cardOneObject.description}  <br />
                                  </Card.Text>
                                  <Button variant="success" href={this.state.cardOneObject.url}
                                                            onClick={() => this.handleCardClick(1, true)}>
                                                            Yes
                                  </Button>{' '}
                                  <Button variant="danger"onClick={() => this.handleCardClick(1, false)}>No</Button>{' '}  <br />
                                  <img
                                    src={this.state.cardOneObject.imageUrl}
                                    className='img-thumbnail'
                                    alt='...'
                                    width="100"
                                    height="100"
                                  />
                                </Card.Body>
                         </Card>
                         <Card className="text-center">
                                <Card.Header>Option #2</Card.Header>
                                <Card.Body>
                                  <Card.Title>
                                      {this.state.cardTwoObject.name} <br />
                                  </Card.Title>
                                  <Card.Text>
                                      {this.state.cardTwoObject.description}  <br />
                                  </Card.Text>
                                  <Button variant="success" href={this.state.cardTwoObject.url}
                                                            onClick={() => this.handleCardClick(2, true)}>
                                                            Yes
                                  </Button>{' '}
                                  <Button variant="danger" onClick={() => this.handleCardClick(2, false)}>No</Button>{' '} <br />
                                  <img
                                    src={this.state.cardTwoObject.imageUrl}
                                    className='img-thumbnail'
                                    alt='...'
                                    width="100"
                                    height="100"
                                  />
                                </Card.Body>
                         </Card>
                         <Card className="text-center">
                                <Card.Header>Option #3</Card.Header>
                                <Card.Body>
                                  <Card.Title>
                                      {this.state.cardThreeObject.name} <br />
                                  </Card.Title>
                                  <Card.Text>
                                      {this.state.cardThreeObject.description}  <br />
                                  </Card.Text>
                                  <Button variant="success" href={this.state.cardThreeObject.url}
                                                            onClick={() => this.handleCardClick(3, true)}>
                                                            Yes
                                  </Button>{' '}
                                  <Button variant="danger" onClick={() => this.handleCardClick(3, false)}>No</Button>{' '} <br />
                                  <img
                                    src={this.state.cardThreeObject.imageUrl}
                                    className='img-thumbnail'
                                    alt='...'
                                    width="100"
                                    height="100"
                                  />
                                </Card.Body>
                         </Card>
                    </div>
                  );
          }
        }
}